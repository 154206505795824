<template>
  <v-app>

    <Navbar></Navbar>

    <v-main>
      <Index></Index>
    </v-main>

    <Footer></Footer>


  </v-app>
</template>

<script>

import Navbar from "./components/Navbar";
import Index from "./components/Index/Index"
import Footer from "./components/Footer";
export default {
  name: 'App',

  components: {
    Navbar,
    Index,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">


@import "assets/css/normalize.css";
@import "assets/css/gird";
@import "assets/css/custom";
@import "assets/css/font.css";

html {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
}

body{
  font-family: "Shabnam FD", Tahoma, Arial, sans-serif !important;

}

#app {
  font-family: "Shabnam FD", Tahoma, Arial, sans-serif !important;
}

</style>
