<template>
  <section class="section-lyla" id="gallery">
    <div class="container-fluid	">
      <v-row>
        <div class="col-md-12 py-0 text-center"><h2 class="section-title white--text">ما
          چیکار کردیم؟</h2>
          <p class="section-subtitle white--text">به دنبال استفاده روز افزون مردم از موبایل و وسایل
            ارتباطی <br>تلاش کردیم برخی از نیاز های مردم را با علم روز و به صورت آنلاین پاسخ بدهیم
          </p></div>


        <div class="gallery-item-wrapper padding-top-30">

          <div style="direction: ltr">
            <div>
              <carousel
                  :autoplay="true"
                  :nav="false"
                  :margin="40"
                  :loop="true"
                  :center="true"
                  :dots="false"
                  :items="6"
                  :responsive="{0:{items:2,dots:true},600:{items:3,dots:true},1024:{items:6,dots:false}}"
              >
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase1.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase2.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase3.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase4.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase5.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase1.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase2.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase3.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase4.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase5.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase1.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase2.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase3.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase4.jpg"></div>
                <div><img alt="showcase" class="gallery-show"
                          src="../../../assets/images/showcase5.jpg"></div>
              </carousel>
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'App',

  components: {carousel},

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">

.section-lyla {
  background: #990fc3;
  background: -webkit-linear-gradient(135deg, #990fc3 0, #760fc3 16%, #652ec3 52%, #652ec3 52%, #0dd3c5 100%, #7db9e8 100%);
  padding: 105px 0 120px 0;

  .gallery-item-wrapper {
    float: left;
    width: 100%;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.gallery-show {
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  border: 8px solid rgba(255, 255, 255, 0.8);
}


</style>
