<template>
  <section class="video-wrapper">
    <div class="video-wrapper-overlay"></div>
    <div class="shape-image-top"></div>
    <v-container>
      <v-row style="margin-top: -15px;margin-bottom: -15px">
        <div class="col-md-12 py-0 text-center"><h3 class="video-title white--text">این
          ویدیو رو ببینید<br><span>مراحل طراحی یک سایت:تحلیل،طراحی،پیاده سازی،تست و رفع باگ،اجرا و پشتیبانی</span>
        </h3>
          <div class="popup-gallery">
            <u-animate-container>
              <u-animate
                  name="bounceIn"
                  delay="0.5s"
                  duration="2s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animate__animated animate__bounceIn"
                  :begin="false"
              >
              <video @click="playing=!playing" class="img-thumbnail" id="videoElement"
                     v-play="playing"
                     poster="../../../assets/video/poster.jpg" preload="none"
              >
                <source src="../../../assets/video/video.mp4" type="video/mp4">
              </video>
              </u-animate>
            </u-animate-container>

          </div>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'
export default {
  name: 'App',
  components: {
    UAnimateContainer,
    UAnimate
  },

  data() {
    return {
      playing: false
    }
  },
  computed: {
    paused() {
      return !this.playing;
    }
  },
  directives: {
    play: {
      bind(el, binding, vnode) {
        el.addEventListener('playing', () => {
          vnode.context[binding.expression] = !el.paused;
        });
        el.addEventListener('pause', () => {
          vnode.context[binding.expression] = !el.paused;
        });
        vnode.context[binding.expression] = !el.paused;
      },
      update(el, binding) {
        if (el.paused) {
          if (binding.value) {
            el.play();
          }
        } else if (!binding.value) {
          el.pause();
        }
      }
    }
  },
  methods: {
    playVideo() {
      this.playing = true;
    },
    pauseVideo() {
      this.playing = false;
    }
  }
};
</script>

<!--
onclick="this.volume=0.2; if(this.paused){this.play()}else {this.pause()}"
-->


<style lang="scss">

.img-thumbnail {
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all .2s ease-in-out;
}

.popup-wrapper{
  display:block;
  position:relative;
  overflow:hidden;
}

.popup-gallery{
  display:inline-block;
  position:relative;
}

.first-gallery{
  margin-bottom:15px;
}

.popup-gallery img {
  position:relative;
  z-index:10;
  width: 100%;
  height: auto;
}

.popup-gallery:hover img {
  opacity: 0.25;
}

.popup-gallery a span.eye-wrapper,
.popup-gallery a span.eye-wrapper2 {
  background-color:#000;
  position: absolute;
  display: block;
  overflow:hidden;
  z-index: 2;
  height:100%!important;
  top: 0%;
  margin-top: 0px;
  left: 0%;
  right: 0%;
  font-size: 22px;
  color:#fff;
  text-align: center;
  font-weight:300;
  opacity: 0;
  border-radius: 5px 5px 0 0;
}

.video-title{
  font-size: 34px;
  line-height: 40px;
  margin-top: 45px;
  color: #fff !important;
  position: relative;
  span {
    font-size: 19px;
    line-height: 24px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.video-wrapper {

  background: url(../../../assets/images/backvideo.jpg) top center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  padding: 155px 0;
  position: relative;

  .video-wrapper-overlay{
    position: absolute;
    background: linear-gradient(
            135deg
        ,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    opacity: 0.88;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .shape-image-top{
    background-size: contain !important;
    background: url(../../../assets/images/shape-top-right.png) no-repeat scroll top center;
    width: 100%;
    max-height: 200px;
    padding: 100px 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  h3{
    margin-bottom: 20px;
    font-weight: 500;
  }



}

</style>
