<template>
  <div style="z-index: 99999">

    <v-navigation-drawer
        v-model="drawer"
        fixed
        right
        class="nav-bg"
        temporary
    >
      <div class="navbar__items" >
        <ul style="flex-direction: column">
          <li class="my-2" @click="drawer=false">
            <a href="/#home_wrapper">خانه</a>
          </li>
          <li class="my-2" @click="drawer=false">
            <a href="/#about">درباره ما</a>
          </li>
          <li class="my-2" @click="drawer=false">
            <a href="/#team">تیم ما</a>
          </li >
          <li class="my-2" @click="drawer=false">
            <a href="/#gallery">گالری</a>
          </li>
          <li class="my-2" @click="drawer=false">
            <a href="/#features">قابلیتهای ما</a>
          </li>
          <li class="my-2 mb-4" @click="drawer=false">
            <a href="/#download-app">ارتباط با ما</a>
          </li>
          <li class="my-2" @click="drawer=false">
            <a href="/#contact" class="discover-btn">شروع کنید!</a>
          </li>
        </ul>
      </div>
    </v-navigation-drawer>

    <v-app-bar
        :absolute="scrollPosition<=400"
        :fixed="scrollPosition>400"
        color="transparent"
        :class="{'fixed-bg':scrollPosition>400}"
        prominent
        elevation="0"
        class="navbar"
    >



      <v-container class="px-0">
        <div class="d-flex align-center">
        <a class="navbar__brand">
          <img width="91" src="../assets/images/novin.png" @click="scrollToTop()" style="height: 300%;margin-top: -25px">
        </a>
        <div class="navbar__items d-none d-md-block">
          <ul>
            <li>
              <a href="/#home_wrapper">خانه</a>
            </li>
            <li>
              <a href="/#about">درباره ما</a>
            </li>
            <li>
              <a href="/#team">تیم ما</a>
            </li>
            <li>
              <a href="/#gallery">گالری</a>
            </li>
            <li>
              <a href="/#features">قابلیتهای ما</a>
            </li>
            <li>
              <a href="/#download-app">ارتباط با ما</a>
            </li>
            <li>
              <a href="/#contact" class="discover-btn">شروع کنید!</a>
            </li>
          </ul>
        </div>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon class="d-block d-md-none" @click="drawer=true" style="color: white" ></v-app-bar-nav-icon>
        </div>
      </v-container>

    </v-app-bar>


  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      scrollPosition: null,
      drawer: false,
    }
  },
  methods: {

    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
};
</script>


<style lang="scss">
.navbar {
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 90px;
  &__items{
    ul{
      display: flex;
      font-size: 17px;
      list-style: none;
      li{
        list-style-type: none;
        .discover-btn{
          background: transparent;
          border: 1px solid #fff;
          border-radius: 8px 8px;
          padding: 12px 24px 14px 24px!important;
          margin-left: 10px;
          margin-right: 15px;
          &:hover{
            color: #337ab7 !important;
            background-color: white;
          }
        }
        a{
          color: #fff !important;
          font-size: 14px !important;
          line-height: 30px !important;
          text-transform: uppercase;
          padding: 10px 20px!important;
          font-weight: 700 !important;
          text-decoration: none;
          outline: 0;
          transition: all 0.5s ease;
          &:hover{
            color: #9bf2f3!important;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
  &__brand {
    text-decoration: none;
    outline: 0;
    height: 50px;
    padding: 15px 15px;
  }
}

.fixed-bg{
  background: linear-gradient(
          135deg
      ,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#FA7268 100%,#7db9e8 100%);
}

.nav-bg{
  background: linear-gradient(90deg,#990fc3, #e36776);
}
</style>
