<template>
  <section class="section-white-address no-padding-bottom" id="contact">
    <v-container>
      <v-row style="margin-top: -15px;margin-bottom: -15px" >
        <div class="col-md-6 py-0" style="padding-right: 15px !important;padding-left: 15px !important;"><h4>شروع کن! ما رو خبر کن!</h4><!----><!---->
          <!---->
          <v-form id="contact-form">

            <v-text-field class="contact-input" label="نام و نام خانوادگی" outlined ></v-text-field>
            <v-text-field class="contact-input" style="direction: ltr" label="ایمیل" outlined ></v-text-field>
            <v-text-field class="contact-input" style="direction: ltr" label="شماره تماس" outlined ></v-text-field>
            <v-textarea class="contact-input" label="متن پیام" outlined ></v-textarea>
            <v-btn elevation="0" class="contact-submit" >ارسال</v-btn>
           </v-form>
        </div>
        <div class="col-md-6 py-0" style="padding-right: 15px !important;padding-left: 15px !important;"><h4>ما کجاییم؟</h4>

          <div class="map-container" style="height: 400px">

            <img src="../../../assets/images/map.jpg" style="width: 100%"/>

<!--            <Cedarmaps
                style="height: 400px"
                :center="center"
                token="542d7d14a3ddcea262c13f4eb512497ecf03888c"
                :zoom="15"
            >
&lt;!&ndash;              <MglMarker :coordinates="marker" color="#985BEF"/>
              <MglNavigationControl position="top-right" />&ndash;&gt;
            </Cedarmaps>-->
          </div>

          <h5 style="margin-top: 20px">دفتر مرکزی</h5>
          <p class="contact-info"><i class="far fa-map"></i>
            مازندران،تنکابن،خیابان فردوسی</p>
          <p class="contact-info"><i class="far fa-envelope"></i><a
              href="mailto:contact@email.com">info@soasoft.ir</a></p>
          <p class="contact-info"><i class="fa fa-phone"></i> 09107601025</p>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>

//import { Cedarmaps, /*MglMarker, MglNavigationControl*/ } from 'vue-cedarmaps'
//import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  name: 'App',

  components: { /*Cedarmaps, MglMarker, MglNavigationControl*/ },

  data() {
    return {
      center:{ lng: '50.859509', lat: '36.828826' },
      marker: [50.859509, 36.828826]
    }
  },
};
</script>


<style lang="scss">
.section-white-address{
  padding: 90px 0 0 0;
  background: #fff;
  h4 {
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    color: #2F362F;
    font-size: 26px;
    font-weight: 500;
  }
  h5 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #2F362F;
    font-weight: 500;
  }
  p.contact-info {
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: #7c8595;
    svg {
      font-size: 18px;
      margin-left: 5px;
      vertical-align: middle;
      color: #985BEF;
    }
    a{
      margin-bottom: 8px;
      color: #7c8595;
      text-decoration: none;
      outline: 0;
      &:hover{
        text-decoration: underline;
      }
    }
  }


  .contact-input {
    width: 100%;
    font-size: 14px;
    border-radius: 5px 5px 5px 5px;
    .v-label {
      font-size: 14px;
    }
    fieldset {
      background-color: #f6f7f8;
      border: 1px solid #ececec;
    }
  }

  .contact-submit{
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 30px 34px !important;
    letter-spacing: normal !important;
    color: #ffffff;
    background: linear-gradient(
            135deg
        , #6B02FF 0%, #985BEF 100%);
    text-transform: uppercase;
    border-radius: 5px;
    transition: all .3s ease-in-out;
    disbled {
      cursor: not-allowed;
      background: #ccc;
      color: #222;
    }
  }

}
</style>
