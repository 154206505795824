<template>
  <section>
    <div class="top-shape-wrapper" id="team">
      <v-container>
        <v-row style="margin-top: -15px">
          <div class="col-md-12 py-0 text-center"><h2 class="section-title">تیم
            ما!</h2>
            <p class="section-subtitle">گروه برنامه نویسی وباتو تیمی قوی و هماهنگ! <br
            >تخصص،همکاری و علم روز نقطه قوت گروه ماست</p></div>


          <div class="gallery-item-wrapper padding-top-30">

            <div style="direction: ltr">
              <div>
                <carousel
                    :autoplay="true"
                    :nav="false"
                    :loop="true"
                    :margin="24"
                    :center="true"
                    :dots="false"
                    :items="1"
                    :responsive="{0:{items:1,dots:true},600:{items:2,dots:true},1024:{items:3,dots:true}}"
                >
                  <div class="team-item"><img alt="pic" class="team-img mx-auto"
                                              src="../../../assets/images/saeed.jpg">
                    <h3>سعید خدارحمی</h3>
                    <div class="team-info"><p>برنامه نویس بکند،مسئول سئو
                      </p></div>
                    <small style="text-align:justify!important;"> برنامه نویسی را از سال 88 شروع کرده و
                      با زبانهای Php, ES6, C, C++, C#, TypeScript آشنایی دارد و حوزه تخصصی او YiiFramework2, VueJs
                      است </small>
                    <ul class="team-icon">
                      <li><a class="twitter" href="#"><i
                          class="fab fa-twitter"></i></a>
                      </li>
                      <li><a class="pinterest" href="#"><i
                          class="fab fa-pinterest"></i></a>
                      </li>
                      <li><a class="facebook" href="#"><i
                          class="fab fa-facebook"></i></a>
                      </li>
                      <li><a class="dribble"
                             href="https://www.instagram.com/saeedsoakh"><i
                          class="fab fa-instagram"></i></a>
                      </li>
                    </ul>
                  </div>

                  <div class="team-item"><img alt="pic" class="team-img mx-auto"
                                              src="../../../assets/images/negin.jpg">
                    <h3>نگین سلمانپور</h3>
                    <div class="team-info"><p>طراح رابط کاربری (UI/UX)
                    </p></div>
                    <small style="text-align:justify!important;">طراحی رابط کاربری را از سال 96 شروع کرد و اکنون طراح یوزر اینترفیس با استفاده از تجربه کاربری به روز است و به Adobe Photoshop و AdobeXD مسلط است</small>
                    <ul class="team-icon">
                      <li><a class="twitter" href="#"><i
                          class="fab fa-twitter"></i></a>
                      </li>
                      <li><a class="pinterest" href="#"><i
                          class="fab fa-pinterest"></i></a>
                      </li>
                      <li><a class="facebook" href="#"><i
                          class="fab fa-facebook"></i></a>
                      </li>
                      <li><a class="dribble" href="#"><i
                          class="fab fa-dribbble"></i></a>
                      </li>
                    </ul>
                  </div>


                  <div class="team-item"><img alt="pic" class="team-img mx-auto"
                                              src="../../../assets/images/omid.jpg">
                    <h3>امید خدارحمی</h3>
                    <div class="team-info"><p>برنامه نویس فرانت اند،بازی
                      ساز</p></div>
                    <small style="text-align:justify!important;"> برنامه نویسی را از سال 91 شروع کرده و
                      با زبانهای Php, ES6, C, C++, C#, Vb آشنایی دارد و حوزه تخصصی او VueJs است و به Electron.js,C#,Unity3D تسلط دارد </small>
                    <ul class="team-icon">
                      <li><a class="twitter" href="#"><i
                          class="fab fa-twitter"></i></a>
                      </li>
                      <li><a class="pinterest" href="#"><i
                          class="fab fa-pinterest"></i></a>
                      </li>
                      <li><a class="facebook" href="#"><i
                          class="fab fa-facebook"></i></a>
                      </li>
                      <li><a class="dribble" href="#"><i
                          class="fab fa-dribbble"></i></a>
                      </li>
                    </ul>
                  </div>


                  <div class="team-item"><img alt="pic" class="team-img mx-auto"
                                              src="../../../assets/images/fatika.jpg">
                    <h3>فاطمه سلمانپور</h3>
                    <div class="team-info"><p>طراح ، گرافیست وب
                    </p></div>
                    <small style="text-align:justify!important;">گرافیک وب سایت را از سال 96 شروع کرد و اکنون طراح گرافیکی صفحات وب و طراح لوگو است و حوزه تخصصی او Adobe Photoshop و Adobe Illustrator است</small>
                    <ul class="team-icon">
                      <li><a class="twitter" href="#"><i
                          class="fab fa-twitter"></i></a>
                      </li>
                      <li><a class="pinterest" href="#"><i
                          class="fab fa-pinterest"></i></a>
                      </li>
                      <li><a class="facebook" href="#"><i
                          class="fab fa-facebook"></i></a>
                      </li>
                      <li><a class="dribble" href="#"><i
                          class="fab fa-dribbble"></i></a>
                      </li>
                    </ul>
                  </div>



                  <div class="team-item"><img alt="pic" class="team-img mx-auto"
                                              src="../../../assets/images/alireza.jpg">
                    <h3>علیرضا خدارحمی</h3>
                    <div class="team-info"><p>برنامه نویس موبایل، دسکتاپ</p></div>
                    <small style="text-align:justify!important;"> برنامه نویسی را از سال 93 شروع کرده و
                      با زبانهای ES6, C, C++, Java, TypeScript آشنایی دارد و حوزه تخصصی او Electron.js ,ReactNative.js
                      است </small>
                    <ul class="team-icon">
                      <li><a class="twitter" href="#"><i
                          class="fab fa-twitter"></i></a>
                      </li>
                      <li><a class="pinterest" href="#"><i
                          class="fab fa-pinterest"></i></a>
                      </li>
                      <li><a class="facebook" href="#"><i
                          class="fab fa-facebook"></i></a>
                      </li>
                      <li><a class="dribble" href="#"><i
                          class="fab fa-dribbble"></i></a>
                      </li>
                    </ul>
                  </div>

                  <div class="team-item"><img alt="pic" class="team-img mx-auto"
                                              src="../../../assets/images/faroghRahmani.jpg">
                    <h3>فاروق رحمانی</h3>
                    <div class="team-info"><p>کو-اُردینیتور تیم
                    </p></div>
                    <small style="text-align:justify!important;">
                       همکار و پشتیبان هماهنگ کننده پروژه در توسعه استانداردهای فرآیند اجرایی، گردآوری اسناد و قرار ملاقات همچنین
                      برنامه‌ریز ملاقات برای هماهنگی کارهای پروژه و ارائه شاخص‌های موفقیت پروژه
                    </small>
                    <ul class="team-icon">
                      <li><a class="twitter" href="#"><i
                          class="fab fa-twitter"></i></a>
                      </li>
                      <li><a class="pinterest" href="#"><i
                          class="fab fa-pinterest"></i></a>
                      </li>
                      <li><a class="facebook" href="#"><i
                          class="fab fa-facebook"></i></a>
                      </li>
                      <li><a class="dribble"
                             href="https://www.instagram.com/saeedsoakh"><i
                          class="fab fa-instagram"></i></a>
                      </li>
                    </ul>
                  </div>

                </carousel>

              </div>
            </div>
          </div>

        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
  name: 'App',
  components: {carousel},

  data() {
    return {
      //
    }
  },
};
</script>

<style lang="scss" scoped>
.owl-theme .owl-nav {
  position: absolute;
  top: 50%;
  justify-content: space-between;
  width: 100%;
  display: flex;
   margin-top: 0px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
</style>


<style lang="scss">
.top-shape-wrapper {
  overflow: hidden;
  padding-top: 70px;

  .gallery-item-wrapper {
    float: left;
    width: 100%;
  }

}

.team-item {
  direction: rtl;
  margin-top: 40px;
  margin-bottom: 4px;
  padding: 50px 20px;
  background-color: #fff;
  border-bottom: 3px solid #e0e0e0;
  box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
  text-align: center;
  border-radius: 8px 8px;
  transition: all 0.25s ease-in-out;

  &:hover {
    margin-top: 25px;
    border-bottom: 3px solid #9561d4;
    transition: all 1s;
  }

  small {
    font-size: 75%;
  }

  .team-info {
    display: block;
    margin-bottom: 0;

    p {
      color: #acacac;
      font-style: italic;
    }

    &::after {
      background: #9561d4;
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 50px;
      height: 3px;
      margin-top: -20px;
    }
  }

  ul.team-icon {
    display: flex;
    justify-content: center;
    li {
      a {
        margin: 0;
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 18px;
        border-radius: 50%;
        color: #fff;
        text-decoration: none;
        outline: 0;
      }
    }
    margin: 25px 0 0 0;
    %shareicondisplay {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px;
    }
    li a.twitter {
      background-color: #4099ff;
      @extend %shareicondisplay;
    }
    li a.pinterest {
      background-color: #C92228;
      @extend %shareicondisplay;
    }
    li a.facebook {
      background-color: #3b5998;
      @extend %shareicondisplay;
    }
    li a.dribble {
      background-color: #ea4c89;
      @extend %shareicondisplay;
    }
  }

  ul {
    padding: 0;
  }

  ul, ol {
    list-style: none;
    color: #858585;
    font-size: 14px;
    line-height: 24px;
  }

  p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: #7c8595;
    margin-bottom: 15px;
  }

  h3 {
    margin: 20px 0 1px 0 !important;
    color: #454545;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all .25s ease-in-out;
  }

  .team-img {
    max-width: 140px;
    padding: 6px 6px;
    vertical-align: middle;
    border: 0;
    background-color: #dedede;
    border-radius: 50%;
  }

}
</style>
