<template>
  <div class="footer">
    <v-container>
      <v-row style="margin-right: -15px;margin-left: -15px;">
        <div class="col-md-12 py-0 text-center" style="position: relative"><p>تمامی حقوق مادی و معنوی متعلق به
          گروه برنامه نویسی وباتو میباشد</p>
          <ul class="footer_social">
            <li><a href="#"><i class="fab fa-twitter"></i></a>
            </li>
            <li><a href="#"><i
                class="fab fa-pinterest"></i></a></li>
            <li><a href="#"><i
                class="fab fa-facebook"></i></a></li>
            <li><a href="#"><i
                class="fab fa-instagram"></i></a></li>
            <li><a href="#"><i
                class="fab fa-skype"></i></a></li>
            <li><a href="#"><i class="fab fa-dribbble"></i></a>
            </li>
          </ul>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">
.footer {
  position: relative;
  overflow: hidden;
  display: block;
  margin-top: 30px;
  padding: 110px 0 55px 0;
  background-color: #fff;
  &:before{
    background: linear-gradient(135deg,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    border-radius: 750px;
    content: "";
    height: 1250px;
    left: -360px;
    position: absolute;
    top: 40px;
    transform: rotate(-184deg);
    width: 2250px;
  }
  p {
    font-size: 16px;
    color: #eaeaea;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  ul {
    padding: 0;
  }
  ul.footer_social {
    width: 100%;
    max-width: 1140px;
    display: block;
    margin: 0 auto;
    padding-top: 5px;
    text-align: center;
    li {
      display: inline-block;
      margin: 0;
      line-height: 100%!important;
      list-style-type: none;
      font-size: 17px;
    }
      a {
        text-decoration: none;
        outline: 0;
        svg {
          display: inline-block;
          margin: 0 8px;
          padding: 0;
          font-size: 26px!important;
          line-height: 26px!important;
          color: #fff;
          transition: all .5s ease-in-out;
          &:hover{
            color: #3c98e7;
          }
        }
      }
  }
  ul {
    list-style: none;
    color: #858585;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
