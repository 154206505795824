<template>
  <section class="section-lyla-shape" id="newsletter-section">
    <v-container>
      <v-row style="margin-top: -15px;margin-bottom: -15px" >
        <div class="col-md-12 text-center padding-top-60 padding-bottom-20"><h3
            class="white-text">به اندازه کافی توضیح دادیم؟<br> اگه میخوای با
          ما کاریو استارت بزنی همین الان شروع کن!</h3></div>
        <div class="col-md-12">
          <div class="newsletter_form_wrapper">
            <div class="newsletter_form_box">
              <div class="newsletter-form" id="newsletter-form">
                <a href="#contact" style="text-decoration: none;">
                <v-btn id="submit-button-newsletter">شروع کن !!!</v-btn>
                </a>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      //
    }
  },
};
</script>

<style lang="scss">
.section-lyla-shape {
  z-index: 10;
  overflow: hidden;
  position: relative;
  padding-top: 90px;
  padding-bottom: 110px;
  .newsletter_form_wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  #newsletter-form #submit-button-newsletter {
    width: 165px;
    height: 65px;
    background: #fff;
    border: none;
    color: #9561d4;
    letter-spacing: normal;
    display: inline;
    margin: 0 0 0 -5px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    vertical-align: top;
    transition: all .25s ease-in-out;
    &:hover{
      color: #2e2e2e;
    }
  }
  &:before{
    background: linear-gradient(
            135deg
        ,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    border-radius: 750px;
    content: "";
    height: 1250px;
    left: -400px;
    position: absolute;
    top: -800px;
    transform: rotate(
            176deg
    );
    width: 2250px;
    z-index: -100;
  }
  h3{
    font-size: 30px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
