<template>
  <section class="home-section" id="home_wrapper">
    <div class="home-section-overlay"></div>
    <v-container>
      <v-row style="margin-top: -15px;margin-bottom: -15px">
        <div class="col-md-7 py-0" style="padding-top: 80px!important;"><h1>ایده های خود را بساز!</h1>
          <p>میخوای پولدار بشی ؟ ایده ای داری ؟ زود باش شروع کن ! کمکت میکنیم تا ایده ات رو پیاده
            سازی کنی</p><a class="btn-green scrool" href="#about">چطور؟</a></div>

        <div class="col-md-5 py-0">
          <u-animate-container>
            <u-animate
                name="bounceIn"
                delay="0.5s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__bounceIn"
                :begin="false"
            >
          <img
            alt="pic"
            width="100%"
            src="../../../assets/images/hero2.png">
            </u-animate>
          </u-animate-container>
        </div>

      </v-row>
    </v-container>

  </section>
</template>

<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'
export default {
  components: {
    UAnimateContainer,
    UAnimate
  },
  name: 'App',

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">

.home-section {
  position: relative;
  z-index: 999;
  overflow: hidden;
  padding-top: 110px;
  padding-bottom: 80px;
  background-color: #f5f6f7;
  a {
    text-decoration: none;
    outline: 0;
    cursor: pointer;
  }

  h1 {
    line-height: 54px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.50);
    font-size: 44px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  p {
    max-width: 600px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #fff !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.50);
    margin: 0 0 30px;
  }

  &:before{
    -webkit-transform: rotate(+174deg);
    background: #FA7268;
    background: -webkit-linear-gradient(135deg, #fa7268 0, #fa7268 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    background: -o-linear-gradient(135deg, #fa7268 0, #fa7268 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    background: -moz-linear-gradient(135deg, #fa7268 0, #fa7268 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    background: linear-gradient(135deg, #fa7268 0, #fa7268 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#990fc3", endColorstr="#7db9e8", GradientType=1 );
    border-radius: 750px;
    content: "";
    height: 1250px;
    right: -400px;
    position: absolute;
    top: -605px;
    transform: rotate(+174deg);
    width: 2250px;
    z-index: -100;
  }
}
.home-section-overlay {
  background: url(../../../assets/images/hero-circles2.png) top center no-repeat;
  width: 100%;
  opacity: 0.89;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.btn-green {
  font-size: 19px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #0ccec0 !important;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.37);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
  letter-spacing: 1px !important;
  border-radius: 5px 5px;
  padding: 20px 36px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  &:hover{
    box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.37);
    filter: brightness(95%);
  }
}
</style>
