<template>
  <section class="download-app-wrapper" id="download-app">
    <u-animate-container>
    <div class="download-app-wrapper-overlay"></div>
    <div class="shape-image-top"></div>

    <v-container>
      <u-animate
          name="slideInRight"
          delay="1s"
          duration="1s"
          :iteration="1"
          :offset="0"
          animateClass="animate__animated animate__slideInRight"
          :begin="false"
      >
      <v-row style="margin-top: -15px;margin-bottom: -15px">
        <div class="col-md-6 padding-top-100" style="position: relative;z-index: 100"><h2
            class="title-download-app-padding white--text">
          ارتباط با ما</h2>
          <p class="white--text"> ما همیشه و در هر زمانی آماده پاسخگویی به شما هستیم! اگه فکراتو
            کردی و میخوای یه استارتاپ حرفه ای رو استارت بزنی یا مشاوره میخوای که چیکار کنی،از طریق شبکه های اجتماعی و یا
            فرم زیر به ما پیام بدهید. </p>
          <ul class="footer_social">
            <li><a href="https://www.instagram.com/soasoft"><i
                class="fab fa-instagram"></i></a></li>
            <li><a
                href="https://www.linkedin.com/in/saeed-khodarahmi-b2131553/"><i
                class="fab fa-linkedin"></i></a></li>
          </ul>
        </div>

        <div class="col-md-6" style="position: relative;z-index: 50"
             >


          <img
            alt="picture" class="download-app-iphone width-100" src="../../../assets/images/eng.svg">


        </div>

      </v-row>
      </u-animate>
    </v-container>
    </u-animate-container>
  </section>
</template>

<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'
export default {
  name: 'App',

  components: {
    UAnimateContainer,
    UAnimate
  },

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">

.download-app-wrapper {
  background-size: cover !important;
  background: url(../../../assets/images/backvideo.jpg) no-repeat fixed top center;
  width: 100%;
  position: relative;
  .download-app-wrapper-overlay {
    position: absolute;
    background: linear-gradient(135deg,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
    opacity: 0.88;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .shape-image-top {
    background-size: contain;
    background: url(../../../assets/images/shape-top-right.png) no-repeat scroll top center;
    width: 100%;
    max-height: 200px;
    padding: 100px 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .title-download-app-padding {
    padding-top: 55px;
  }

  h2{
    font-size: 38px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 500;
  }

  p{
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  ul {
    padding: 0;
  }

  ul.footer_social {
    width: 100%;
    max-width: 1140px;
    display: block;
    margin: 0 auto;
    padding-top: 5px;
    text-align: center;
    li {
      display: inline-block;
      margin: 0;
      line-height: 100%!important;
      list-style-type: none;
      font-size: 17px;
      a {
        text-decoration: none;
        outline: 0;

        svg {
          display: inline-block;
          margin: 0 8px;
          padding: 0;
          font-size: 32px!important;
          line-height: 26px!important;
          color: #fff;
          transition: all .25s ease-in-out;
          &:hover{
            color: #3c98e7;
          }
        }
      }
    }
  }

}

</style>
