<template>
  <section class="section-white-phone small-padding-bottom" id="features">
    <v-container>
      <v-row>
        <div class="col-md-12 pt-0 text-center padding-bottom-10"><h2
            class="section-title">اپلیکیشن
          موبایل</h2>
          <p class="section-subtitle">اپلیکیشن های موبایل ما با قابلیت های مختلف توانایی اجرای هر
            استارتاپی را دارد</p></div>
      </v-row>
      <v-row>
        <div class="col-md-4 py-0">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="0.25s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeIn"
                :begin="false"
            >
              <div class="feature-box"><i
                  class="fa fa-server iconitem light-green"></i>
                <div class="feature-box-text"><h4>حداقل منابع</h4><small
                >با توجه به کد نویسی اصولی و استفاده از ترفند های نرم افزاری اپلیکیشن هایی سریع وبا
                  استفاده از حداقل منابع سخت افزاری را خواهید داشت</small></div>
              </div>
            </u-animate>
          </u-animate-container>
        </div>
        <div class="col-md-4 py-0">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="0.5s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeIn"
                :begin="false"
            >
          <div class="feature-box"><i
              class="fa fa-user iconitem light-blue"></i>
            <div class="feature-box-text"><h4>رابط کاربری</h4><small
            >تجربه رابط کابری و طراحی کاربرپسند یکی از اصول طراحی اپلیکیشن است که باعث اقبال
              بیشتر هر استارتاپی میشود</small></div>
          </div>
            </u-animate>
          </u-animate-container>
        </div>
        <div class="col-md-4 py-0">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="0.75s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeIn"
                :begin="false"
            >
          <div class="feature-box" ><i
              class="fa fa-bell iconitem light-orange"></i>
            <div class="feature-box-text"><h4>اعلان های حرفه ای</h4><small
            >یکی از قابلیت های مهم اپلیکیشن موبایل اعلان های حرفه ای و سفارشی برای کربران است که
              همیشه کاربر را مجاب به استفاده از اپ میکند</small></div>
          </div>
            </u-animate>
          </u-animate-container>
        </div>
      </v-row>
      <v-row>
        <div class="col-md-4 py-0">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="1s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeIn"
                :begin="false"
            >
          <div class="feature-box"><i
              class="fa fa-comment iconitem green"></i>
            <div class="feature-box-text"><h4>چت آنلاین</h4><small
            >یکی از مهمترین مسائل در اپ های امروزه امکان چت آنلاین با استفاده از برنامه نویسی
              سوکت است</small></div>
          </div>
            </u-animate>
          </u-animate-container>
        </div>
        <div class="col-md-4 py-0">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="1.25s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeIn"
                :begin="false"
            >
          <div class="feature-box"><i
              class="fas fa-mobile-alt iconitem blue"></i>
            <div class="feature-box-text"><h4>نسخه iOS و Android</h4><small
            >ما چون از جدید ترین متد برنامه نویسی موبایل استفده میکنیم نسخه های اندروید و iOS به
              صورت موازی تولید میشوند و میتوان هردوی آنها را به راحتی در اختیار داشت</small></div>
          </div>
            </u-animate>
          </u-animate-container>
        </div>
        <div class="col-md-4 py-0">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="1.5s"
                duration="2s"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeIn"
                :begin="false"
            >
          <div class="feature-box"><i
              class="fas fa-dollar-sign iconitem orange"></i>
            <div class="feature-box-text"><h4>صرفه جویی مالی</h4><small
            >سرعت و دقت در کد نویسی را افزایش دادیم و زبانی را برای موبایل استفاده کردیم که با
              آن فیس بوک و اینستاگرام نوشته شده اند و کمترین خطا را دارند که این باعث صرفه جویی مالی هم همیشود</small>
            </div>
          </div>
            </u-animate>
          </u-animate-container>
        </div>
      </v-row>
      <v-row>
        <div class="col-md-12 pb-0 padding-top-40">
          <u-animate-container>
            <u-animate
                name="fadeInUp"
                delay="1s"
                duration="2s"
                class="extra-image"
                :iteration="1"
                :offset="0"
                animateClass="animate__animated animate__fadeInUp"
                :begin="false"
            >
          <img alt="home-iphone"
               class=" width-100"
               src="../../../assets/images/iphone-v.png">
            </u-animate>
          </u-animate-container>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'

export default {
  name: 'App',

  components: {
    UAnimateContainer,
    UAnimate
  },

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">
.section-white-phone {
  background: #fff;

  .extra-image {
    position: relative;
    margin-top: 30px;
    margin-bottom: -230px;
    z-index: 100;
    border: 0;
  }

  .feature-box.orange i {
    background: #fe9c24 !important;
  }

  .orange {
    background-color: #FC6A3F !important;
  }

  .green {
    background-color: #87AC34 !important;
  }

  .light-green {
    background-color: #A9D129 !important;
  }

  .blue {
    background-color: #3473B8 !important;
  }

  .light-blue {
    background-color: #7CB8DD !important;
  }

  .light-orange {
    background-color: #FE9C24 !important;
  }

  .iconitem {
    float: left;
    padding: 15px 15px;
    font-size: 45px;
    line-height: 45px;
    width: 75px;
    height: 75px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    background: #7566e3;
  }

  .feature-box {
    overflow: hidden;
    margin: 40px 0 0 0;

    h4 {
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 1.2;
      margin-top: 0;
      font-weight: 500;
    }

    small {
      font-size: 75%;
    }

    .feature-box-text {
      line-height: 1.42857143;
      color: #333;
      float: left;
      width: 60%;
      margin-left: 30px;
    }
  }
}

.section-white-phone.small-padding-bottom {
  padding: 90px 0 70px 0;
}
</style>
