<template>
  <section class="section-grey small-padding-top" id="about">
    <div class="container">
      <v-row style="margin-top: -15px;margin-bottom: -15px" >
        <div class="col-md-12 py-0 text-center"><h2 class="section-title">ما کی
          هستیم؟</h2>
          <p class="section-subtitle">ما یک گروه برنامه نویسی هستیم که تمام نیاز های یک استارتاپ رو
            میتونیم برطرف کنیم<br>اما چطور؟</p></div>
        <div class="col-md-4 py-0">
          <div class="main-services"><img alt="pic" class="width-100"
                                          src="../../../assets/images/mobile-app.svg">
            <h3>ساخت اپلیکیشن موبایل</h3>
            <p>امروزه بهترین راه ارتباط با مشتری ساخت اپلیکیشن موبایل است که قابل نصب روی تمامی
              گوشی های اندروید و ios باشد</p></div>
        </div>
        <div class="col-md-4 py-0">
          <div class="main-services"><img alt="pic" class="width-100"
                                          src="../../../assets/images/web.svg">
            <h3>طراحی وبسایت</h3>
            <p>برای مدیریت و کار دقیق تر کسب و کار خود باید وبسایتی رو طراحی کنید که برای تمامی
              پلتفرم ها قابل دسترس باشد و در گوگل قابل شناسایی باشد</p></div>
        </div>
        <div class="col-md-4 py-0">
          <div class="main-services"><img alt="pic" class="width-100"
                                          src="../../../assets/images/computer.svg">
            <h3>نرم افزار دسکتاپ</h3>
            <p>برای کاربران ادارات و اپراتور ها بهترین گزنه ساخت یک اپلیکیشن دستکاپ است که برروی مک
              و ویندوز و لینوکس قابل اجرا باشد</p></div>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">

.section-grey{
  background: #f5f6f7;

  p{
    margin: 0 0 10px;
  }

  .main-services {
    min-height: 384.5px;
    margin-top: 40px;
    padding: 50px 30px 30px 30px;
    background-color: #fff;
    border-bottom: 3px solid #e0e0e0;
    box-shadow: rgba(33,33,33,.06) 0 4px 24px 5px;
    border-radius: 8px;
    text-align: center;
    transition: all 1s;
    p {
      font-size: 17px;
      line-height: 28px;
      font-weight: 400;
      color: #7c8595;
      margin-bottom: 15px;
      padding: 0 10px;
    }
    h3 {
      font-size: 22px;
      color: #292929;
      margin-bottom: 15px;
      line-height: 1.2;
      margin-top: 0;
      font-weight: 500;
    }
    img {
      width: 100px;
      margin-bottom: 25px;
    }
    &:hover{
      margin-top: 25px;
      border-bottom: 3px solid #9561d4;
      transition: all 1s;
    }
  }

}
</style>
