<template>
  <section class="section-white">
    <div class="container">
      <v-row style="margin-top: -15px;margin-bottom: -15px">
        <div class="col-md-12 py-0 margin-bottom-40 text-center"><h2
            class="section-title">ویژگی های
          ما</h2>
          <p class="section-subtitle">استفاده اصولی از تمامی استانداردهای روز دنیا<br
          >و استفاده از متد های روز برای تحلیل،طراحی،پیاده سازی و پشتیبانی</p></div>
        <div class="col-md-4 py-0 padding-top-40">
          <div class="features_item">
            <div class="dropcaps_right"><span
                class="fa fa-laptop features_icons"></span></div>
            <div class="text_align_right"><h4>طراحی واکنش گرا</h4>
              <p>طراحی ها ما برای تمامی سایز صفحات چه در موبایل و چه در وبسایت و اپ دسکتاپ نسبت به
                سایز صفحه نمایش مطلوبی را به کاربر ارائه میدهد</p></div>
          </div>
          <div class="features_item">
            <div class="dropcaps_right"><span
                class="fa fa-code features_icons"></span></div>
            <div class="text_align_right"><h4>کد نویسی تمیز</h4>
              <p>کد نویسی بر اساس متد های روز و با رعایت استانداردهای بین المللی سبب شده است که کدی
                تمیز به کارفرما ارائه شود</p></div>
          </div>
          <div class="features_item">
            <div class="dropcaps_right"><span
                class="fa fa-camera-retro features_icons"></span>
            </div>
            <div class="text_align_right"><h4>طراحی اختصاصی</h4>
              <p>طراحی اختصاصی به فرم دلخواه کارفرما مهمترین مسئله در جلب رضایت مشتریان و کارفرما
                است</p></div>
          </div>
        </div>


        <div class="col-md-4 py-0 "
             >
          <u-animate-container>
          <u-animate
              name="bounceIn"
              delay="0.5s"
              duration="2s"
              :iteration="1"
              :offset="0"
              animateClass="animate__animated animate__bounceIn"
              :begin="false"
          ><img
            alt="picture"
            class="width-100"
            src="../../../assets/images/features-iphone.jpg">
          </u-animate>
          </u-animate-container>
        </div>


        <div class="col-md-4 py-0 padding-top-40">
          <div class="features_item">
            <div class="dropcaps_left"><span
                class="fa fa-leaf features_icons"></span></div>
            <div class="text_align_left"><h4>طراحی مدرن</h4>
              <p>ما همواره از بهترین زبانهای برنامه نویسی دنیا برای طراحی های مدرن و پیشرفته خود
                استفاده میکنیم</p></div>
          </div>
          <div class="features_item">
            <div class="dropcaps_left"><span
                class="fa fa-rocket features_icons"></span></div>
            <div class="text_align_left"><h4>امکانات حرفه ای</h4>
              <p>از انواع امکانات حرفه ای از قبیل نقشه،اعلان، درگاه بانکی و اس ام اس و... استفاده
                میشود که بتواند تمامی نیازهای شما را برآورده کند</p></div>
          </div>
          <div class="features_item">
            <div class="dropcaps_left"><span
                class="fa fa-comments features_icons"></span></div>
            <div class="text_align_left"><h4>پشتیبانی 24/7</h4>
              <p>پس از مرحله پیاده سازی نوبت به پشتیبانی میرسد که گروه ما 24 ساعته و در 7 روز هفته
                پشتیبان سایت و اپ های طراحی شده میباشد</p></div>
          </div>
        </div>
      </v-row>
    </div>
  </section>
</template>

<script>

import {UAnimateContainer, UAnimate} from 'vue-wow'


export default {
  name: 'App',

  components: {
    UAnimateContainer,
    UAnimate
  },

  data() {
    return {
      //
    }
  },
};
</script>


<style lang="scss">
.section-white {
  background: #fff;
  padding: 90px 0 100px 0;
  p{
    margin: 0 0 10px;
  }
  .features_item {
    margin-bottom: 40px;
    p{
      font-size: 17px;
      line-height: 28px;
      font-weight: 400;
      color: #7c8595;
      margin-bottom: 15px;
    }
    h4{
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 15px;
      text-transform: uppercase;
      line-height: 1.2;
      margin-top: 0;
    }
    .dropcaps_right {
      background: linear-gradient(
              135deg
          ,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
      width: 76px;
      height: 76px;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      font-size: 32px;
      position: relative;
      transition: all .50s ease-in-out;
      border-radius: 25px 25px 0 25px;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0 50px 25px;
      span.features_icons {
        font-size: 32px !important;
      }
    }

    .dropcaps_left {
      background: linear-gradient(
              135deg
          ,#990fc3 0,#760fc3 16%,#652ec3 52%,#652ec3 52%,#0dd3c5 100%,#7db9e8 100%);
      width: 76px;
      height: 76px;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      font-size: 32px;
      position: relative;
      transition: all .50s ease-in-out;
      border-radius: 25px 25px 25px 0;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 25px 50px 0;
      span.features_icons {
        font-size: 32px !important;
      }
    }
  }

}


</style>
